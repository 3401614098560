import React from "react";
import { Route, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"));
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// landing

// dashboard
const EcommerceDashboard = React.lazy(
	() => import("../pages/dashboard/Ecommerce/")
);

// menu

const Employee = React.lazy(() => import("../pages/employee"));
const CreateEmployee = React.lazy(
	() => import("../pages/employee/createEmployee")
);
const Stores = React.lazy(() => import("../pages/store"));
const ConfigStores = React.lazy(() => import("../pages/store/configStores"));

const Config = React.lazy(() => import("../pages/config"));
const LoginLogManager = React.lazy(() => import("../pages/login_log_manager"));

const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));

export interface RoutesProps {
	path: RouteProps["path"];
	name?: string;
	component?: RouteProps["component"];
	route?: any;
	exact?: RouteProps["exact"];
	icon?: string;
	header?: string;
	roles?: string[];
	children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
	path: "/",
	exact: true,
	component: () => <Root />,
	route: Route,
};

// dashboards
const dashboardRoutes: RoutesProps = {
	path: "/dashboard/ecommerce",
	name: "Dashboards",
	component: EcommerceDashboard,
	icon: "airplay",
	header: "Navigation",
	route: PrivateRoute,
};

// auth
const authRoutes: RoutesProps[] = [
	{
		path: "/auth/login",
		name: "Login",
		component: Login,
		route: Route,
	},
	{
		path: "/auth/confirm",
		name: "Confirm",
		component: Confirm,
		route: Route,
	},
	{
		path: "/auth/forget-password",
		name: "Forget Password",
		component: ForgetPassword,
		route: Route,
	},
	{
		path: "/auth/lock-screen",
		name: "Lock Screen",
		component: LockScreen,
		route: Route,
	},
	{
		path: "/auth/logout",
		name: "Logout",
		component: Logout,
		route: Route,
	},
];

// menu

const employeeRoutes: RoutesProps[] = [
	{
		path: "/employee",
		name: "Employee",
		component: Employee,
		route: PrivateRoute,
	},
];

const storesRoutes: RoutesProps[] = [
	{
		path: "/store",
		name: "stores",
		component: Stores,
		route: PrivateRoute,
	},
];
const configRoutes: RoutesProps[] = [
	{
		path: "/config",
		name: "config",
		component: Config,
		route: PrivateRoute,
	},
];

const loginLogManagerRoutes: RoutesProps[] = [
	{
		path: "/login-log",
		name: "login-log",
		component: LoginLogManager,
		route: PrivateRoute,
	},
];

// Create, detail

const configStoresRoutes: RoutesProps[] = [
	{
		path: "/store/:mode",
		component: ConfigStores,
		exact: true,
		route: PrivateRoute,
	},
];

const creteEmployeeRoutes: RoutesProps[] = [
	{
		path: "/employee/:mode",
		component: CreateEmployee,
		exact: true,
		route: PrivateRoute,
	},
];

const Error404Routes: RoutesProps[] = [
	{
		path: "*",
		component: Error404,
		route: PrivateRoute,
	},
];
const Error500Routes: RoutesProps[] = [
	{
		path: "/500",
		component: Error500,
		route: PrivateRoute,
	},
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
	let flatRoutes: RoutesProps[] = [];

	routes = routes || [];
	routes.forEach((item: RoutesProps) => {
		flatRoutes.push(item);

		if (typeof item.children !== "undefined") {
			flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
		}
	});
	return flatRoutes;
};

// All routes
const authProtectedRoutes = [
	rootRoute,
	dashboardRoutes,
	// ...appRoutes,
	// extrapagesRoutes,
	// uiRoutes,
	...configStoresRoutes,
	...creteEmployeeRoutes,
	...employeeRoutes,
	...storesRoutes,
	...configRoutes,
	...loginLogManagerRoutes,
	...Error500Routes,
	...Error404Routes,
];
// const publicRoutes = [...authRoutes, ...otherPublicRoutes];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
	publicRoutes,
	authProtectedFlattenRoutes,
	publicProtectedFlattenRoutes,
	authProtectedRoutes,
};
